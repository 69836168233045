<template>
    <div class="w-full wrapper" v-loading.fullscreen.lock="loading" :element-loading-text="$t('connecting')" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="header-items df aic mb-6">
            <div class="header-container gap-2">
                <div class="header-item " :class="activeName==1 ? 'header-item-active': ''" @click="activeName=1" v-if="role!='reservoir'">
                    <div class="header-radio-label"></div>
                    {{ $t('building_and_structures') }}
                </div>
                <div class="header-item" @click="activeName=2" :class="activeName==2 ? 'header-item-active': ''" v-if="role=='admin' || role=='reservoir'">
                    <div class="header-radio-label"></div>
                    {{ $t('hydraulic_structures') }}
                </div>
                <div class="header-item" @click="activeName=3" :class="activeName==3 ? 'header-item-active': ''" v-if="role=='admin'">
                    <div class="header-radio-label"></div>
                    {{ $t('organizations') }}
                </div>
                <div class="header-item" @click="activeName=4" :class="activeName==4? 'header-item-active': ''" v-if="role=='admin' || role=='org_admin' || role=='ministry_admin'">
                    <div class="header-radio-label"></div>
                    {{ $t('in_terms_regions') }}
                </div>
                <div class="header-item" @click="activeName=5" :class="activeName==5? 'header-item-active': ''" v-if="role=='admin' || role=='org_admin' || role=='ministry_admin'">
                    <div class="header-radio-label"></div>
                    {{ $t('transport_and_facilities') }}
                </div>
            </div>
        </div>

        <div class="filterStatistic" v-if="(role==='admin' || role == 'org_admin' || role=='ministry_admin' ) && activeName!=4  && activeName!=2 ">
            <el-select v-model="filter" v-if="activeName==1">
                <el-option value="region" :label="$t('provinces')"></el-option>
                <el-option value="buildinggroup" :label="$t('categories')"></el-option>
            </el-select>
            <el-select v-model="organization" v-if="activeName==3">
                <el-option v-for="(item, i) in CatList" :key="i" :value="item.id" :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr "></el-option>
            </el-select>
            <el-select v-model="region" v-if="filter=='region' && activeName!=3 && activeName!=4">
                <el-option :value="null" :label="$t('all_provinces')" :placeholder="$t('all_provinces')"></el-option>
                <el-option v-for="item in regions" :key="item.id" :value="item.id" :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr "></el-option>
            </el-select>
        </div>

        <div class="mb-4" v-if="activeName!=4">
            <div class="cards w-full flex fw-w" v-if="activeName==1">
                <div @click="$router.push('/building-list/exg')" class="card1" v-if="statistica.existing_buildings || statistica.existing_buildings==0">
                    <div class="card p-4 relative">
                        <div class="w-full flex justify-end gap-2 ">
                            <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                            <img class="h-12" src="@/assets/img/icons/flat.svg" alt="">
                        </div> 
                        <div class="card-text">
                            <h1>{{ statistica.existing_buildings | formatNumber  }} {{ $t('ta') }}</h1>
                            <p>{{$t('existing_buildings_structures')}}</p>
                        </div>
                    </div>
                </div>
                <div @click="$router.push('/building-list/new')" class="card1" v-if="statistica.new_buildings || statistica.new_buildings==0">
                    <div class="card relative p-4">
                        <div class="w-full flex justify-end gap-2 ">
                            <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                            <img class="h-12" src="@/assets/img/icons/organization.svg" alt="">
                        </div> 
                        <div class="card-text">
                            <h1>{{ statistica.new_buildings | formatNumber  }} {{ $t('ta') }}</h1>
                            <p>{{$t('new_buildings_structures')}}</p>
                        </div>
                    </div>
                </div>
                <div @click="$router.push(`/building-list/${role=='ministry_admin'? 'new':'exg'}`)" class="card1" v-if="statistica['total_#_buildings'] || statistica['total_#_buildings']==0">
                    <div class="card p-4 relative"> 
                        <div class="w-full flex justify-end gap-2 ">
                            <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                            <img class="h-12" src="@/assets/img/icons/building.svg" alt="">
                        </div> 
                        <div class="card-text">
                            <h1>{{ statistica['total_#_buildings'] | formatNumber }} {{ $t('ta') }}</h1>
                            <p>{{$t('total_#_buildings_structures')}}</p>
                        </div>
                    </div>
                </div>
                <div @click="$router.push('/statistica/building/1,2')" class="card1 " v-if="statistica.sustainable_buildings || statistica.sustainable_buildings==0">
                    <div class="card relative p-4"> 
                        <div class="w-full flex justify-end gap-2 ">
                            <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                            <img class="h-12" src="@/assets/img/safebuilding.svg" alt="">
                        </div> 
                        <div class="card-text">
                            <h1>{{ statistica.sustainable_buildings | formatNumber }} {{ $t('ta') }}</h1>
                            <p>{{$t('sustainable_buildings')}}</p>
                        </div>
                    </div>
                </div>


              <div @click="$router.push('/statistica/building/3')" class="card1 " v-if="statistica.not_sustainable_buildings || statistica.not_sustainable_buildings==0 ">
                    <div class="card relative p-4"> 
                      <div class="w-full flex justify-end gap-2 ">
                            <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                            <img class="h-12" src="@/assets/img/not_sustainable_buildings.svg" alt="">
                        </div> 
                      <div class="card-text">
                        <h1>{{ statistica.not_sustainable_buildings | formatNumber }} {{ $t('ta') }}</h1>
                        <p>{{$t('not_sustainable_buildings')}}</p>
                      </div>
                    </div>
                </div>
                <div @click="$router.push('/statistica/building/4')" class="card1 " v-if="statistica.not_sustainable_buildings4 || statistica.not_sustainable_buildings4==0">
                    <div class="card relative p-4">
                        <div class="w-full flex justify-end gap-2 ">
                            <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                            <img class="h-12" src="@/assets/img/buildingbreak.svg" alt="">
                        </div> 
                        <div class="card-text">
                            <h1>{{ statistica.not_sustainable_buildings4 | formatNumber }} {{ $t('ta') }}</h1>
                            <p>{{$t('not_sustainable_buildings4')}}</p>
                        </div>
                    </div>
                </div>
                <div @click="$router.push('/statistica/buildings/uncomplate')" class="card1" v-if="statistica['an_incomplete_building'] || statistica['an_incomplete_building']==0">
                    <div class="card relative p-4">
                        <div class="w-full flex justify-end gap-2 ">
                            <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                            <img class="h-12" src="@/assets/img/icons/an_incomplete_building.svg" alt="">
                        </div> 
                        <div class="card-text">
                            <h1>{{ statistica['an_incomplete_building'] | formatNumber }} {{ $t('ta') }}</h1>
                            <p>{{$t('an_incomplete_building')}}</p>
                        </div>
                    </div>
                </div>
                <div @click="$router.push('/statistica/buildings/uncomplatenew')" class="card1" v-if="statistica['an_incomplete_new_building'] || statistica['an_incomplete_new_building']==0">
                    <div class="card relative p-4"> 
                        <div class="w-full flex justify-end gap-2 ">
                            <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                            <img class="h-12" src="@/assets/img/icons/uncomplatenew.svg" alt="">
                        </div> 
                        <div class="card-text">
                            <h1>{{ statistica['an_incomplete_new_building'] | formatNumber }} {{ $t('ta') }}</h1>

                            <p>{{$t('an_incomplete_new_building')}}</p>
                        </div>
                    </div>
                </div>
                <div class="card1" v-if="statistica['users'] || statistica['users']==0">
                    <div class="card p-4 relative">
                        <div class="w-full flex justify-end gap-2 ">
                            <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                            <img class="h-12" src="@/assets/img/icons/team.svg" alt="">
                        </div> 
                        <div class="card-text">
                            <h1>{{ statistica['users'] | formatNumber }} {{ $t('ta') }}</h1>

                            <p>{{$t('users')}}</p>
                        </div>
                    </div>
                </div>
                <div @click="$router.push('/statistica/transport/1,2')" class="card1" v-if="statistica['sustainable_tf'] || statistica['sustainable_tf']==0 && ['admin', 'org_admin'].includes(role)">
                  <div class="card p-4 relative"> 
                    <div class="w-full flex justify-end gap-2 ">
                            <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                            <img class="h-12" src="@/assets/img/icons/transport_category_1.svg" alt="">
                        </div> 
                    <div class="card-text">
                      <h1>{{ statistica['sustainable_tf'] | formatNumber }} {{ $t('ta') }}</h1>

                      <p>{{$t('transport_category_1')}}</p>
                    </div>
                  </div>
                </div>
                <div @click="$router.push('/statistica/transport/3')" class="card1" v-if="statistica['not_sustainable_tf3'] || statistica['not_sustainable_tf3']==0 && ['admin', 'org_admin'].includes(role)">
                  <div class="card p-4 relative"> 
                    <div class="w-full flex justify-end gap-2 ">
                            <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                            <img class="h-12" src="@/assets/img/icons/transport_category_2.svg" alt="">
                        </div> 
                    <div class="card-text">
                      <h1>{{ statistica['not_sustainable_tf3'] | formatNumber }} {{ $t('ta') }}</h1>
                      <p>{{$t('transport_category_2')}}</p>
                    </div>
                  </div>
                </div>
                <div @click="$router.push('/statistica/transport/4')" class="card1" v-if="statistica['not_sustainable_tf4'] || statistica['not_sustainable_tf4']==0 && ['admin', 'org_admin'].includes(role)">
                  <div class="card p-4 relative"> 
                    <div class="w-full flex justify-end gap-2 ">
                            <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                            <img class="h-12" src="@/assets/img/icons/transport_category_3.svg" alt="">
                        </div> 
                    <div class="card-text">
                        <h1>{{ statistica['not_sustainable_tf4'] | formatNumber }} {{ $t('ta') }}</h1>
                        <p>{{$t('transport_category_3')}}</p>
                    </div>
                  </div>
                </div>
                <div @click="$router.push('/investment/3')" class="card1 " v-if="statistica.not_sustainable_buildings || statistica.not_sustainable_buildings==0 ">
                  <div class="card relative p-4"> 
                    <div class="w-full flex justify-end gap-2 ">
                            <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                            <img class="h-12" src="@/assets/img/icons/invest_card.svg" alt="">
                        </div> 
                    <div class="card-text">
                      <h1>{{ statistica.invest_buildings | formatNumber }} {{ $t('ta') }}</h1>
                      <p>{{$t('invest_not_sustainable_buildings')}}</p>
                    </div>
                  </div>
                </div>
            </div>
            <div v-if="activeName==2" class="w-full grid grid-cols-11 gap-4">
                <div class="col-span-2"></div>
                <div class="col-span-3">
                    <div class="reservoir-statistic-info w-full">{{ $t('strong') }}</div>
                </div>
                <div class="col-span-3">
                    <div class="reservoir-statistic-info unsafe">{{ $t('weak') }}</div>
                </div>
                <div class="col-span-3">
                    <div class="reservoir-statistic-info checking">{{ $t('unattested_count') }}</div>
                </div>
                <div class="col-span-2">
                    <div class="card only-icon"> 
                        <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                        <img class="h-20" src="@/assets/img/icons/water-tower.svg" alt=""> 
                    </div>
                </div>
                <div  class="col-span-3">
                    <div class="card p-4 only-icon" >
                        <div class="card-text">
                            <h1 class="text-center">{{ statistica.sustainable_reservoir?statistica.sustainable_reservoir:0 | formatNumber  }} {{ $t('ta') }}</h1>
                            <p class="text-center">{{$t('sustainable_reservoir')}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-span-3">
                    <div class="card p-4 only-icon" >
                        <div class="card-text">
                            <h1 class="text-center">{{ statistica.not_sustainable_reservoir?statistica.not_sustainable_reservoir:0 | formatNumber  }} {{ $t('ta') }}</h1>
                            <p class="text-center">{{$t('not_sustainable_reservoir')}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-span-3">
                    <div class="card p-4 only-icon" >
                        <div class="card-text">
                            <h1 class="text-center">{{ statistica.reservoir?statistica.reservoir :0 | formatNumber  }} {{ $t('ta') }}</h1>
                            <p class="text-center">{{$t('all_reservoir_count')}}</p>
                        </div>
                    </div>
                </div>

                <div  class="col-span-2">
                    <div class="card only-icon"> 
                        <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                        <img class="h-20" src="@/assets/img/icons/dam.svg" alt=""> 
                    </div>
                </div>
                <div class="col-span-3">
                    <div class="card p-4 only-icon" >
                        <div class="card-text">
                            <h1 class="text-center">{{ statistica.sustainable_reservoir?statistica.sustainable_reservoir:0 | formatNumber  }} {{ $t('ta') }}</h1>
                            <p class="text-center">{{$t('sustainable_hydro_units')}}</p>
                        </div>
                    </div>
                </div>
                <div  class="col-span-3">
                    <div class="card p-4 only-icon" >
                        <div class="card-text">
                            <h1 class="text-center">{{ statistica.not_sustainable_hydro_units?statistica.not_sustainable_hydro_units:0 | formatNumber  }} {{ $t('ta') }}</h1>
                            <p class="text-center">{{$t('not_sustainable_reservoir')}}</p>
                        </div>
                    </div>
                </div>
                <div  class="col-span-3">
                    <div class="card p-4 only-icon" >
                        <div class="card-text">
                            <h1 class="text-center">{{ statistica.hydro_units?statistica.hydro_units:0 | formatNumber  }} {{ $t('ta') }}</h1>
                            <p class="text-center">{{$t('all_hydro_units')}}</p>
                        </div>
                    </div>
                </div>

                <div  class="col-span-2">
                    <div class="card only-icon"> 
                        <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                        <img class="h-20" src="@/assets/img/icons/reservoir-hydro.svg" alt=""> 
                    </div>
                </div>
                <div class="col-span-3">
                    <div class="card p-4 only-icon" >
                        <div class="card-text">
                            <h1 class="text-center">{{ statistica.sustainable_pumping_station?statistica.sustainable_pumping_station:0 | formatNumber  }} {{ $t('ta') }}</h1>
                            <p class="text-center">{{$t('sustainable_pumping_station')}}</p>
                        </div>
                    </div>
                </div>
                <div  class="col-span-3">
                    <div class="card p-4 only-icon" >
                        <div class="card-text">
                            <h1 class="text-center">{{ statistica.not_sustainable_pumping_station?statistica.not_sustainable_pumping_station:0 | formatNumber  }} {{ $t('ta') }}</h1>
                            <p class="text-center">{{$t('not_sustainable_pumping_station')}}</p>
                        </div>
                    </div>
                </div>
                <div  class="col-span-3">
                    <div class="card p-4 only-icon" >
                        <div class="card-text">
                            <h1 class="text-center">{{ statistica.pumping_station?statistica.pumping_station:0 | formatNumber  }} {{ $t('ta') }}</h1>
                            <p class="text-center">{{$t('all_pumping_station_count')}}</p>
                        </div>
                    </div>
                </div>

                <div  class="col-span-2">
                    <div class="card only-icon"> 
                        <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                        <img class="h-20" src="@/assets/img/icons/hydroelectricity.svg" alt=""> 
                    </div>
                </div>
                <div class="col-span-3">
                    <div class="card p-4 only-icon" >
                        <div class="card-text">
                            <h1 class="text-center">{{ statistica.sustainable_hydroelectric_power_stations?statistica.sustainable_hydroelectric_power_stations:0 | formatNumber  }} {{ $t('ta') }}</h1>
                            <p class="text-center">{{$t('sustainable_hydroelectric_power_stations')}}</p>
                        </div>
                    </div>
                </div>
                <div  class="col-span-3">
                    <div class="card p-4 only-icon" >
                        <div class="card-text">
                            <h1 class="text-center">{{ statistica.not_sustainable_hydroelectric_power_stations?statistica.not_sustainable_hydroelectric_power_stations:0 | formatNumber  }} {{ $t('ta') }}</h1>
                            <p class="text-center">{{$t('not_sustainable_hydroelectric_power_stations')}}</p>
                        </div>
                    </div>
                </div>
                <div  class="col-span-3">
                    <div class="card p-4 only-icon" >
                        <div class="card-text">
                            <h1 class="text-center">{{ statistica.hydroelectric_power_stations?statistica.hydroelectric_power_stations:0 | formatNumber  }} {{ $t('ta') }}</h1>
                            <p class="text-center">{{$t('all_hydroelectric_power_stations_count')}}</p>
                        </div>
                    </div>
                </div>

                <div  class="col-span-2">
                    <div class="card only-icon"> 
                        <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                        <img class="h-20" src="@/assets/img/icons/bridge.svg" alt=""> 
                    </div>
                </div>
                <div class="col-span-3">
                    <div class="card p-4 only-icon" >
                        <div class="card-text">
                            <h1 class="text-center">{{ statistica.sustainable_canals?statistica.sustainable_canals:0 | formatNumber  }} {{ $t('ta') }}</h1>
                            <p class="text-center">{{$t('sustainable_canals')}}</p>
                        </div>
                    </div>
                </div>
                <div  class="col-span-3">
                    <div class="card p-4 only-icon" >
                        <div class="card-text">
                            <h1 class="text-center">{{ statistica.not_sustainable_canals?statistica.not_sustainable_canals:0 | formatNumber  }} {{ $t('ta') }}</h1>
                            <p class="text-center">{{$t('not_sustainable_canals')}}</p>
                        </div>
                    </div>
                </div>
                <div  class="col-span-3">
                    <div class="card p-4 only-icon" >
                        <div class="card-text">
                            <h1 class="text-center">{{ statistica.canals?statistica.canals:0 | formatNumber  }} {{ $t('ta') }}</h1>
                            <p class="text-center">{{$t('all_canals_count')}}</p>
                        </div>
                    </div>
                </div>

                <div  class="col-span-2">
                    <div class="card only-icon"> 
                        <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                        <img class="h-20" src="@/assets/img/icons/sea.svg" alt=""> 
                    </div>
                </div>
                <div class="col-span-3">
                    <div class="card p-4 only-icon total-reservoir" >
                        <div class="card-text">
                            <h1 class="text-center">{{ statistica['total_#_reservoirs'] ?statistica['total_#_reservoirs'] :0 | formatNumber  }} {{ $t('ta') }}</h1>
                            <p class="text-center">{{$t('total_#_reservoirs_structures_count')}}</p>
                        </div>
                    </div>
                </div> 
            </div> 
            <div class="cards w-full flex fw-w" v-if="activeName==3">
                <div @click="$router.push('/building-list/exg')" class="card1">
                    <div class="card p-4">
                        <div class="w-full flex justify-end gap-2 ">
                            <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                            <img class="h-12" src="@/assets/img/icons/flat.svg" alt="">
                        </div> 
                        <div class="card-text">
                            <h1>{{ count.existing_buildings | formatNumber }} {{ $t('ta') }}</h1>
                            <p>{{$t('existing_buildings_structures')}}</p>
                        </div>
                    </div>
                </div>
                <div @click="$router.push('/building-list/new')" class="card1">
                    <div class="card p-4">
                        <div class="w-full flex justify-end gap-2 ">
                            <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                            <img class="h-12" src="@/assets/img/icons/organization.svg" alt="">
                        </div> 
                        <div class="card-text">
                            <h1>{{ count.new_buildings | formatNumber }} {{ $t('ta') }}</h1>
                            <p>{{$t('new_buildings_structures')}}</p>
                        </div>
                    </div>
                </div>
                <div @click="$router.push('/building-list/exg')" class="card1">
                    <div class="card p-4">
                        <div class="w-full flex justify-end gap-2 ">
                            <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                            <img class="h-12" src="@/assets/img/icons/building.svg" alt="">
                        </div> 
                        <div class="card-text">
                            <h1>{{ count.all_buildings | formatNumber }} {{ $t('ta') }}</h1>
                            <p>{{$t('total_#_buildings_structures')}}</p>
                        </div>
                    </div>
                </div>
                <div @click="$router.push('/statistica/building/1,2')" class="card1">
                    <div class="card p-4"> 
                        <div class="w-full flex justify-end gap-2 ">
                            <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                            <img class="h-12" src="@/assets/img/safebuilding.svg" alt="">
                        </div> 
                        <div class="card-text">
                          
                            <h1>{{ count.sustainable_buildings | formatNumber }} {{ $t('ta') }}</h1>
                            <p>{{$t('sustainable_buildings')}}</p>
                        </div>
                    </div>
                </div>
                <div @click="$router.push('/statistica/building/3')" class="card1">
                    <div class="card p-4"> 
                        <div class="w-full flex justify-end gap-2 ">
                            <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                            <img class="h-12" src="@/assets/img/not_sustainable_buildings.svg" alt="">
                        </div> 
                        <div class="card-text">
                            <h1>{{ count.not_sustainable_buildings | formatNumber }} {{ $t('ta') }}</h1>
                            <p>{{$t('not_sustainable_buildings')}}</p>
                        </div>
                    </div>
                </div>
                <div @click="$router.push('/statistica/building/4')" class="card1">
                    <div class="card p-4">
                        <div class="w-full flex justify-end gap-2 ">
                            <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                            <img class="h-12" src="@/assets/img/buildingbreak.svg" alt="">
                        </div> 
                        <div class="card-text">
                            <h1>{{ count.not_sustainable_buildings4 | formatNumber  }} {{ $t('ta') }}</h1>
                            <p>{{$t('not_sustainable_buildings4')}}</p>
                        </div>
                    </div>
                </div>
                <div class="card1">
                    <div class="card p-4"> 
                        <div class="w-full flex justify-end gap-2 ">
                            <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                            <img class="h-12" src="@/assets/img/icons/team.svg" alt="">
                        </div> 
                        <div class="card-text">
                            <h1>{{ count.users | formatNumber  }} {{ $t('ta') }}</h1>
                            <p>{{$t('users')}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cards w-full" v-if="activeName==5">
                <div @click="$router.push('/transport')" class="card1">
                    <div class="card p-4"> 
                        <div class="w-full flex justify-end gap-2 ">
                            <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                            <img class="h-12" src="@/assets/img/icons/bridge-circle.svg" alt="">
                        </div> 
                        <div class="card-text"> 
                            <h1>{{ transportFacilities&& transportFacilities.seismic && transportFacilities.seismic.all_transport_infrastructure ? transportFacilities.seismic.all_transport_infrastructure:0  }} {{ $t('ta') }}</h1>
                            <p >{{ $t('transport_facilities') }}</p>
                        </div>
                    </div>
                </div>
                <div @click="$router.push('/transport')" class="card1">
                    <div class="card p-4"> 
                        <div class="w-full flex justify-end gap-2 ">
                            <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                            <img class="h-12" src="@/assets/img/safebuilding.svg" alt="">
                        </div> 
                        <div class="card-text">
                            <h1>{{ transportFacilities && transportFacilities.seismic && transportFacilities.seismic.first_seismic ? transportFacilities.seismic.first_seismic:0  }} {{ $t('ta') }}</h1>
                            <p style="font-size: 13px;">{{ $t('sustainable_transport_structures') }}</p>
                        </div>
                    </div>
                </div>
                <div @click="$router.push(`/building-list/${role=='ministry_admin'? 'new':'exg'}`)" class="card1">
                    <div class="card p-4"> 
                        <div class="w-full flex justify-end gap-2 ">
                            <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                            <img class="h-12" src="@/assets/img/not_sustainable_buildings.svg" alt="">
                        </div> 
                        <div class="card-text">
                            <h1>{{ transportFacilities && transportFacilities.seismic && transportFacilities.seismic.second_seismic ? transportFacilities.seismic.second_seismic : 0 }} {{ $t('ta') }}</h1>
                            <p style="font-size: 13px;">{{ $t('not_sustainable_transport') }}</p>
                        </div>
                    </div>
                </div>
              <div @click="$router.push('/statistica/building/3')" class="card1 ">
                    <div class="card p-4"> 
                      <div class="w-full flex justify-end gap-2 ">
                            <img class="h-12 absolute top-3 left-3" src="@/assets/img/card_ pattern.png" alt="">
                            <img class="h-12" src="@/assets/img/buildingbreak.svg" alt="">
                        </div> 

                      <div class="card-text">
                        <h1>{{ transportFacilities && transportFacilities.seismic && transportFacilities.seismic.third_seismic ? transportFacilities.seismic.third_seismic : 0 }} {{ $t('ta') }}</h1>
                        <p style="font-size: 13px;">{{ $t('not_sustainable_transport4_structures') }}</p>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="rendered">
            <div class="diagrams grid grid-cols-2 gap-4" style="padding-bottom:20px;" v-if="activeName==1 || activeName==5">
                <div class="obj-create flex items-center w-full" style="flex:1; min-height:100%;">
                    <apexchart type="donut" class="w-full" ref="chart3" :height="activeName==1 ? 300:300.5" :options="activeName==1 ? chartOptions3 :chartOptionsTransport3" :series="series"></apexchart>
                </div>
                <div  class="obj-create flex items-center w-full" style="  min-height:100%; flex:1;">
                    <apexchart type="bar" class="w-full" ref="chart4" :height="activeName==1 ? 300:300.5" :options="activeName==1 ? chartOptions4 :chartOptionsTransport4" :series="series2"></apexchart>
                </div>
            </div>
            <div class="diagrams grid grid-cols-1 gap-4 mb-4" v-if="(role=='admin' || role == 'org_admin' || role=='ministry' || role=='ministry_admin' ) && activeName==1 || activeName==5">
                <div class="obj-create" style=" padding-top:50px;">
                        <div class="w-full statisic-sub-header">
                            <button class="statisic-sub-header-button " :class="statType=='COUNT'?'active':''" @click="statType='COUNT'">{{ $t('by_number') }}</button>
                            <button class="statisic-sub-header-button" :class="statType=='PROCENT'?'active':''" @click="statType='PROCENT'">{{ $t('by_procent') }}</button>
                        </div>
                        <div class="w-full" v-show="statType=='COUNT'" >
                        <apexchart type="bar" ref="chart1" :height="activeName==1 ? 500 : 500.5" :options="chartOptions1" :series="activeName==1 ? RegionSeries : TransportSeries"></apexchart>

                        </div>
                        <div class="w-full" v-show="statType=='PROCENT'" >
                            <apexchart type="bar" ref="chart2" :height="activeName == 1 ? 500 : 500.5" :options="chartOptions2" :series="activeName==1 ? RegionSeries : TransportSeries"></apexchart>
                        </div>
                        
                    </div>
                    <div v-if="1==2" class="obj-create" style="  padding-top:50px;">
                        <apexchart type="bar" ref="chart2" :height="activeName == 1 ? 500 : 500.5" :options="chartOptions2" :series="activeName==1 ? RegionSeries : TransportSeries"></apexchart>
                    </div>
            </div>
          
        </template>
        <div class="diagrams flex justify-between fw-w " v-if="(role=='admin' || role == 'org_admin' || role=='ministry_admin') && activeName==1 || activeName==5">
            <div class="obj-create flex items-center w-full owf-h" style="min-height:100%; flex:1;">
                <UzMap :data="MapData"  v-if="activeName == 1"/>
                <UzMap :data="MapDataTransport"  v-if="activeName == 5"/>
            </div>
        </div>

        <!-- 3 -->
        <div class="diagrams flex justify-between fw-w mt-3" v-if="role=='admin'  && activeName==3">
            <div class="obj-create" style="width:100%;">
                <div class="diagram-div">
                    <div class="diagram-div-item" v-for="(item,i) in Org" :key="i">
                        <p class="title fw-600"> {{i+1}}. {{ $i18n.locale!='cyr'? item[`organization_${$i18n.locale}`]? item[`organization_${$i18n.locale}`] : item.organization :item.organization}}</p>
                        <div class="flex pt-2 pb-2">
                            <div class="pt-2 w-40px">
                                <p class="diagram-div-text">{{$t('existing_buildings')}}: <span class="fw-600">{{item.existing_buildings | formatNumber }} {{ $t('ta') }}</span></p>
                                <p class="diagram-div-text">{{$t('new_buildings')}}: <span class="fw-600">{{item.new_buildings | formatNumber }} {{ $t('ta') }}</span></p>
                                <p class="diagram-div-text">{{$t('all_buildings')}}: <span class="fw-600">{{item.all_buildings | formatNumber }} {{ $t('ta') }}</span></p>
                                <p class="diagram-div-text">{{$t('users')}}: <span class="fw-600">{{item.users | formatNumber }} {{ $t('ta') }}</span></p>
                                <p class="diagram-div-link mt-2" @click="$router.push(`/statistica/buildings/uncomplate?id=${item.id}&name=${item.organization}`)">{{$t('an_incomplete_building')}}: <span class="fw-600 ">{{item.an_incomplete_building}} {{ $t('ta') }}</span></p>
                                <p class="diagram-div-link" @click="$router.push(`/statistica/buildings/uncomplatenew?id=${item.id}&name=${item.organization}`)">{{$t('an_incomplete_new_building')}}: <span class="fw-600 ">{{item.an_incomplete_new_building }} {{ $t('ta') }}</span></p>

                            </div>
                            <div style="flex:1; " class="flex">

                                <table class="org-building-statistics">
                                    <tr>
                                        <td class="org-building-statistics-text">{{$t('sustainable_buildings')}}</td>
                                        <td style="width:40px"><img style="width:16px" src="@/assets/img/safebuilding.svg" alt=""></td>
                                        <td style="width:220px">
                                            <div class="building-progress-bar">
                                                <div class="building-progress-bar-bar" style="background:#00FF66;" :style="{'width':`${((item.sustainable_buildings*100)/item.all_buildings)}%`}" ></div>
                                                <hr>
                                            </div>
                                        </td>
                                        <td class="org-building-statistics-number">{{item.sustainable_buildings | formatNumber }}</td>
                                    </tr>
                                    <tr>
                                        <td class="org-building-statistics-text">{{$t('not_sustainable_buildings')}}</td>
                                        <td style="width:40px"><img style="width:16px" src="@/assets/img/not_sustainable_buildings.svg" alt=""></td>
                                        <td style="width:220px">
                                            <div class="building-progress-bar" >
                                                <div class="building-progress-bar-bar" style="background:#EAD200;"  :style="{'width':`${((item.not_sustainable_buildings*100)/item.all_buildings)}%`}"></div>
                                                <hr>
                                            </div>
                                        </td>
                                        <td class="org-building-statistics-number">{{item.not_sustainable_buildings | formatNumber }}</td>
                                    </tr>
                                    <tr>
                                        <td class="org-building-statistics-text">{{$t('not_sustainable_buildings4')}}</td>
                                        <td style="width:40px"><img style="width:20px" src="@/assets/img/buildingbreak.svg" alt=""></td>
                                        <td style="width:220px">
                                            <div class="building-progress-bar" >
                                                <div class="building-progress-bar-bar" style="background:#FF0000;" :style="{'width':`${((item.not_sustainable_buildings4*100)/item.all_buildings)}%`}"></div>
                                                <hr>
                                            </div>
                                        </td>
                                        <td class="org-building-statistics-number">{{item.not_sustainable_buildings4 | formatNumber }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <!-- 4 -->
        <div class="filterStatistic flex justify-between items-center" v-if="(role==='admin' || role==='org_admin' || role=='ministry_admin') && activeName==4">
            <div class="flex items-center" style="flex-wrap:wrap">
                <el-select v-if="role==='admin'" class="region-select mr-2 mt-1" v-model="mainFilter" @change="buildingTypeFilter">
                    <el-option class="cut-text" :label="$t('all_buildings')" :value="''"></el-option>
                    <el-option class="cut-text" :label="$t('new_buildings')"  :value="'NEW'"></el-option>
                    <el-option class="cut-text" :label="$t('existing_buildings')" :value="'EXG'"></el-option>
                    <el-option class="cut-text" :label="$t('transport_and_facilities')" :value="'transport_facility'"></el-option>
                </el-select>
                <el-select v-if="role==='admin'" :placeholder="$t('organizations')" @change="OrgIdchange" :disabled="!OrganizarionsList1" class="region-select cut-text mr-2 mt-1" v-model="org_id1">
                    <el-option class="cut-text" :label="$t('all_organizations')" :value="''"></el-option>
                    <el-option class="cut-text" v-for="(item,i) in OrganizarionsList1" :key="i" :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr " :value="item.id"></el-option>
                </el-select>
                <el-select v-if="role==='admin'" placeholder="-----------------" :disabled="org_id1==''" class="region-select cut-text mr-2 mt-1" v-model="org_id2">
                    <el-option class="cut-text" v-for="(item,i) in OrganizarionsList2" :key="i" :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr " :value="item.id"></el-option>
                </el-select>
                <el-select v-model="region_statistic" class="mr-2 mt-1" :disabled="org_id2==''">
                    <el-option :value="0" :label="$t('all_regions')"></el-option>
                    <el-option v-for="item in regions" :key="item.id" :value="item.id" :label="item[`name_${$i18n.locale}`] ? item[`name_${$i18n.locale}`] : item.name_cyr "></el-option>
                </el-select>
            </div>
            <div>
                <button v-if="!transport_facility_condition" class="flex mt-1 items-center fw-600 fs-13" @click="DownloadExel(org_id2==''? '/building/generate/organization/statistic/':`/building/generate/organization/statistic/${org_id2}/${region_statistic? region_statistic+'/':''}`, `Ташкилот Ҳисоботи`)">
                    <p class="mr-1 c-primary" style="opacity:0.8;">{{$t('download')}}</p>
                    <img style="width:40px;" src="@/assets/img/icons/excel-download.svg" alt="">
                </button>
                <button v-if="transport_facility_condition" class="flex mt-1 items-center fw-600 fs-13" @click="DownloadExel(org_id2==''? '/transport_facility/get_region_seismic/':`/transport_facility/get_region_seismic/?main_org=${org_id2}&region${region_statistic}`, `Ташкилот Ҳисоботи`)">
                    <p class="mr-1 c-primary" style="opacity:0.8;">{{$t('download')}}</p>
                    <img style="width:40px;" src="@/assets/img/icons/excel-download.svg" alt="">
                </button>
            </div>
        </div>
        <div class="diagrams flex justify-between fw-w mt-3" v-if="( !transport_facility_condition && role=='admin' || role=='org_admin' )  && activeName==4">
            <div class="obj-create" style="width:100%;">
                <el-table :data="StatisticRegion" style="width: 100%">
                    <el-table-column label="№" width="50px" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.id">
                                {{ (scope.$index + 1) }}
                            </div>
                            <div class="  total-count flex items-center justify-center" v-else>
                                {{ '*' }}
                            </div>
                        </template>

                    </el-table-column>
                    <el-table-column :label="$t('territory')"  align="center" v-if="region_statistic">
                        <template slot-scope="scope">
                            <div class="table-link-button" style="padding:8px;" v-if="region_statistic">
                                <template>
                                    {{ $i18n.locale !='cyr' && scope.row[`district_${$i18n.locale}`] ?scope.row[`district_${$i18n.locale}`]  : scope.row.district  }}
                                </template> 
                            </div>
                            <div class="table-link-button total-count" v-else>{{scope.row.region}}</div>
                            <div class="table-link-button" style="padding:8px;" v-if="region_statistic && !(scope.row[`district_cyr`] || scope.row[`district_ru`] || scope.row.district)">
                                {{ scope.row.region }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('territory')" align="center" v-else>
                        <template slot-scope="scope">
                            <div class="table-link-button" style="padding:8px;" v-if="scope.row.id">{{$i18n.locale!='cyr' ? scope.row[`region_${$i18n.locale}`] ? scope.row[`region_${$i18n.locale}`] : scope.row.region : scope.row.region}}</div>
                            <div class="table-link-button total-count" v-else>{{scope.row.region}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="mainFilter !== 'transport_facility' ? $t('sustainable_buildings_structures') : $t('transport_category_1')" align="center">
                        <template slot-scope="scope">
                            <div class="flex items-center justify-center w-full" v-if="scope.row.id ">
                                <router-link tag="a" :to="`/statistica/regionbuildings/${region_statistic}/${scope.row.id}/${org_id2!=''?org_id2 :0}?type=2&district=${scope.row.district? $i18n.locale!='cyr' ? scope.row[`district_${$i18n.locale}`] ? scope.row[`district_${$i18n.locale}`] : scope.row.district : scope.row.district:$i18n.locale!='cyr' ? scope.row[`region_${$i18n.locale}`] ? scope.row[`region_${$i18n.locale}`] : scope.row.region : scope.row.region}`" class="table-link-button c-green mr-2" style="padding:8px;"><i class="el-icon-view mr-2 fw-600"></i>{{scope.row.count_sustainable | formatNumber }} та</router-link>
                                <el-button class="el-button-download"  @click="DownloadExel(org_id2==''? `/building/generate/regional/statistics/${scope.row.id}/1/`: `/building/generate/organization/${org_id2}/${region_statistic? 'district/'+scope.row.id:'region/'+scope.row.id}/finalconclution/1/`, `Сейсмик мустаҳкам бинолар Ҳисоботи`)">
                                    <i class="el-icon-download fs-13"></i>
                                </el-button>
                            </div>
                            <div v-else class="flex items-center justify-center w-full table-link-button c-green mr-2 total-count">
                                {{scope.row.count_sustainable | formatNumber }} {{$t('ta')}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="mainFilter !== 'transport_facility' ? $t('not_sustainable_buildings_structures') : 'Инструментал техник текширув талаб этиладиган транспорт иншоотлар'" align="center">
                        <template slot-scope="scope">
                            <div class="flex items-center justify-center w-full" v-if="scope.row.id">
                                <router-link tag="a" :to="`/statistica/regionbuildings/${region_statistic}/${scope.row.id}/${org_id2!=''?org_id2 :0}?type=3&district=${scope.row.district? $i18n.locale!='cyr' ? scope.row[`district_${$i18n.locale}`] ? scope.row[`district_${$i18n.locale}`] : scope.row.district : scope.row.district:$i18n.locale!='cyr' ? scope.row[`region_${$i18n.locale}`] ? scope.row[`region_${$i18n.locale}`] : scope.row.region : scope.row.region}`" class="table-link-button mr-2 c-yellow" style="padding:8px;"><i class="el-icon-view mr-2 fw-600"></i>{{scope.row.count_not_sustainable | formatNumber }} та</router-link>
                                <el-button class="el-button-download" @click="DownloadExel(org_id2==''? `/building/generate/regional/statistics/${scope.row.id}/3/`:`/building/generate/organization/${org_id2}/${region_statistic? 'district/'+scope.row.id:'region/'+scope.row.id}/finalconclution/3/`, `Инструментал техник текширув талаб этиладиган бинолар Ҳисоботи`)">
                                    <i class="el-icon-download   fs-13"></i>
                                </el-button>
                            </div>

                            <div v-else class="flex items-center justify-center w-full table-link-button mr-2 c-yellow total-count">
                                {{scope.row.count_not_sustainable | formatNumber }} {{$t('ta')}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="mainFilter !== 'transport_facility' ? $t('not_sustainable_buildings4_structures') : 'Сейсмик заиф транспорт иншоотлар'" align="center">
                        <template slot-scope="scope">
                            <div class="flex items-center justify-center w-full" v-if="scope.row.id">
                                <router-link tag="a" :to="`/statistica/regionbuildings/${region_statistic}/${scope.row.id}/${org_id2!=''?org_id2 :0}?type=4&district=${scope.row.district? $i18n.locale!='cyr' ? scope.row[`district_${$i18n.locale}`] ? scope.row[`district_${$i18n.locale}`] : scope.row.district : scope.row.district:$i18n.locale!='cyr' ? scope.row[`region_${$i18n.locale}`] ? scope.row[`region_${$i18n.locale}`] : scope.row.region : scope.row.region}`" class="table-link-button mr-2 c-red" style="padding:8px;"><i class="el-icon-view mr-2 fw-600"></i>{{scope.row.count_not_sustainable4 | formatNumber }} та</router-link>
                                <el-button class="el-button-download" @click="DownloadExel(org_id2==''? `/building/generate/regional/statistics/${scope.row.id}/4/`:`/building/generate/organization/${org_id2}/${region_statistic? 'district/'+scope.row.id:'region/'+scope.row.id}/finalconclution/4/`, `Сейсмик заиф бинолар Ҳисоботи`)">
                                    <i class="el-icon-download    fs-13"></i>
                                </el-button>
                            </div>
                            <div class="flex items-center justify-center w-full table-link-button mr-2 c-red total-count" v-else>
                                    <span>{{scope.row.count_not_sustainable4 | formatNumber }} {{$t('ta')}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="mainFilter !== 'transport_facility' ? $t('total_#_buildings_structures') : 'Барча транспорт иншоотлар'" align="center">
                        <template slot-scope="scope">
                            <div class="flex items-center justify-center w-full" v-if="scope.row.id">
                                <router-link tag="a" :to="`/statistica/regionbuildings/${region_statistic}/${scope.row.id}/${org_id2!=''?org_id2 :0}?type=1&district=${scope.row.district? $i18n.locale!='cyr' ? scope.row[`district_${$i18n.locale}`] ? scope.row[`district_${$i18n.locale}`] : scope.row.district : scope.row.district:$i18n.locale!='cyr' ? scope.row[`region_${$i18n.locale}`] ? scope.row[`region_${$i18n.locale}`] : scope.row.region : scope.row.region}`" class="table-link-button mr-2" style="padding:8px;"><i class="el-icon-view mr-2 fw-600"></i>{{scope.row.count_building | formatNumber }} та</router-link>
                                <el-button class="el-button-download" @click="DownloadExel(org_id2==''? `/building/generate/regional/statistics/${scope.row.id}/`:`/building/generate/organization/${org_id2}/${region_statistic? 'district/'+scope.row.id:'region/'+scope.row.id}/finalconclution/`, `Барча бино ва иншоотлар Ҳисоботи`)">
                                    <i class="el-icon-download   fs-13"></i>
                                </el-button>
                            </div>
                            <div v-else class="flex items-center justify-center w-full table-link-button total-count" >
                                {{scope.row.count_building | formatNumber }} {{$t('ta')}}
                            </div>
                        </template>
                    </el-table-column>

                </el-table>

            </div>
        </div>
        <div class="diagrams flex justify-between fw-w mt-3" v-if="(transport_facility_condition && role=='admin' || role=='org_admin')  && activeName==4">
       
            <div class="obj-create" style="width:100%;">
                <el-table :data="transport_facility_statistic" style="width: 100%">
                    <el-table-column label="№" width="50px" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.id">
                                {{ (scope.$index + 1) }}
                            </div>
                            <div class="  total-count flex items-center justify-center" v-else>
                                {{ '*' }}
                            </div>
                        </template>

                    </el-table-column>
                    <el-table-column :label="$t('territory')"  align="center" v-if="transport_facility_statistic">
                        <template slot-scope="scope">
                            <div class="table-link-button" style="padding:8px;" v-if="scope.row.id">{{ $i18n.locale!='cyr'&& scope.row[`name_${$i18n.locale}`] ? scope.row[`name_${$i18n.locale}`] : scope.row.name_cyr  }}  aaa</div>
                            <div class="table-link-button total-count" v-else>{{scope.row.region}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('territory')" align="center" v-else>
                        <template slot-scope="scope">
                            <div class="table-link-button" style="padding:8px;" v-if="scope.row.id">{{$i18n.locale!='cyr' && scope.row[`region_${$i18n.locale}`] ? scope.row[`region_${$i18n.locale}`] : scope.row.region}}</div>
                            <div class="table-link-button total-count" v-else>{{scope.row.region}} </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('sustainable_transport_structures')" align="center">
                        <template slot-scope="scope">
                            <div class="flex items-center justify-center w-full" v-if="scope.row.id">
                                <router-link tag="a" :to="`/statistica/regionbuildings/${region_statistic}/${scope.row.id}/${org_id2!=''?org_id2 :0}?type=2&district=${scope.row.district? $i18n.locale!='cyr' ? scope.row[`district_${$i18n.locale}`] ? scope.row[`district_${$i18n.locale}`] : scope.row.district : scope.row.district:$i18n.locale!='cyr' ? scope.row[`region_${$i18n.locale}`] ? scope.row[`region_${$i18n.locale}`] : scope.row.region : scope.row.region}`" class="table-link-button c-green mr-2" style="padding:8px;"><i class="el-icon-view mr-2 fw-600"></i>{{scope.row.seismic.first_seismic | formatNumber }} та</router-link>
                                <el-button class="el-button-download"  @click="DownloadExel(org_id2==''? `transport_facility/get_therd_siesmic/?seismic=1&region=${scope.row.id}`: `transport_facility/get_therd_siesmic/?seismic=1&district=${region_statistic}&main_org=${org_id2}&region=${scope.row.id}`, `Сейсмик мустаҳкам транспорт иншоотлар Ҳисоботи`)">
                                    <i class="el-icon-download fs-13"></i>
                                </el-button>
                            </div>
                            <div class="flex items-center justify-center w-full table-link-button c-green mr-2 total-count" v-else>
                                {{ scope.row.seismic.first_seismic | formatNumber }} {{$t('ta')}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('not_sustainable_transport')" align="center">
                        <template slot-scope="scope">
                            <div class="flex items-center justify-center w-full" v-if="scope.row.id">
                                <router-link tag="a" :to="`/statistica/regionbuildings/${region_statistic}/${scope.row.id}/${org_id2!=''?org_id2 :0}?type=3&district=${scope.row.district? $i18n.locale!='cyr' ? scope.row[`district_${$i18n.locale}`] ? scope.row[`district_${$i18n.locale}`] : scope.row.district : scope.row.district:$i18n.locale!='cyr' ? scope.row[`region_${$i18n.locale}`] ? scope.row[`region_${$i18n.locale}`] : scope.row.region : scope.row.region}`" class="table-link-button mr-2 c-yellow" style="padding:8px;"><i class="el-icon-view mr-2 fw-600"></i>{{scope.row.seismic.second_seismic | formatNumber }} та</router-link>
                                <el-button class="el-button-download"  @click="DownloadExel(org_id2==''? `transport_facility/get_therd_siesmic/?seismic=3&region=${scope.row.id}`: `transport_facility/get_therd_siesmic/?seismic=3&district=${region_statistic}&main_org=${org_id2}&region=${scope.row.id}`, `Инструментал техник текширув талаб этиладиган транспорт иншоотлар Ҳисоботи`)">
                                    <i class="el-icon-download fs-13"></i>
                                </el-button>
                            </div>
                            <div v-else class="flex items-center justify-center w-full table-link-button mr-2 c-yellow total-count">
                                {{scope.row.seismic.second_seismic | formatNumber }} {{$t('ta')}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('not_sustainable_transport4_structures')" align="center">
                        <template slot-scope="scope">
                            <div class="flex items-center justify-center w-full" v-if="scope.row.id">
                                <router-link tag="a" :to="`/statistica/regionbuildings/${region_statistic}/${scope.row.id}/${org_id2!=''?org_id2 :0}?type=4&district=${scope.row.district? $i18n.locale!='cyr' ? scope.row[`district_${$i18n.locale}`] ? scope.row[`district_${$i18n.locale}`] : scope.row.district : scope.row.district:$i18n.locale!='cyr' ? scope.row[`region_${$i18n.locale}`] ? scope.row[`region_${$i18n.locale}`] : scope.row.region : scope.row.region}`" class="table-link-button mr-2 c-red" style="padding:8px;"><i class="el-icon-view mr-2 fw-600"></i>{{scope.row.seismic.third_seismic | formatNumber }} та</router-link>
                                <el-button class="el-button-download"  @click="DownloadExel(org_id2==''? `transport_facility/get_therd_siesmic/?seismic=4&region=${scope.row.id}`: `transport_facility/get_therd_siesmic/?seismic=4&district=${region_statistic}&main_org=${org_id2}&region=${scope.row.id}`, `Сейсмик заиф транспорт иншоотлар Ҳисоботи`)">
                                    <i class="el-icon-download fs-13"></i>
                                </el-button>
                            </div>
                            <div class="flex items-center justify-center w-full table-link-button mr-2 c-red total-count" v-else>
                                {{scope.row.seismic.third_seismic | formatNumber }} {{$t('ta')}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('total_#_transport_structures')" align="center">
                        <template slot-scope="scope">
                            <div class="flex items-center justify-center w-full" v-if="scope.row.id">
                                <router-link tag="a" :to="`/statistica/regionbuildings/${region_statistic}/${scope.row.id}/${org_id2!=''?org_id2 :0}?type=1&district=${scope.row.district? $i18n.locale!='cyr' ? scope.row[`district_${$i18n.locale}`] ? scope.row[`district_${$i18n.locale}`] : scope.row.district : scope.row.district:$i18n.locale!='cyr' ? scope.row[`region_${$i18n.locale}`] ? scope.row[`region_${$i18n.locale}`] : scope.row.region : scope.row.region}`" class="table-link-button mr-2" style="padding:8px;"><i class="el-icon-view mr-2 fw-600"></i>{{scope.row.seismic.all_transport_infrastructure | formatNumber }} та</router-link>
                                <el-button class="el-button-download"  @click="DownloadExel(org_id2==''? `transport_facility/get_therd_siesmic/?region=${scope.row.id}`: `transport_facility/get_therd_siesmic/?district=${region_statistic}&main_org=${org_id2}&region=${scope.row.id}`, `Сейсмик заиф транспорт иншоотлар Ҳисоботи`)">
                                    <i class="el-icon-download fs-13"></i>
                                </el-button>
                            </div>
                            <div class="flex items-center justify-center w-full table-link-button total-count" v-else>
                                {{scope.row.seismic.all_transport_infrastructure | formatNumber }} {{$t('ta')}}

                            </div>
                        </template>
                    </el-table-column>

                </el-table>

            </div>
        </div>
        <div class="diagrams flex justify-between fw-w mt-3" v-if="role=='ministry_admin' && activeName==4">
            <div class="obj-create" style="width:100%;">
                <el-table :data="StatisticRegion" style="width: 100%">
                    <el-table-column label="№" width="50px" align="center">
                        <template slot-scope="scope">{{ scope.row.id ? (scope.$index + 1): "*" }}</template>
                    </el-table-column>
                    <el-table-column :label="$t('territory')" align="center" v-if="region_statistic">
                        <template slot-scope="scope">
                            <div class="table-link-button" style="padding:8px;"> {{ $i18n.locale!='cyr' && scope.row[`district_${$i18n.locale}`] ? scope.row[`district_${$i18n.locale}`] : scope.row.district  }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('territory')" align="center" v-else>
                        <template slot-scope="scope">
                            <div class="table-link-button" style="padding:8px;">{{$i18n.locale!='cyr'&&scope.row[`region_${$i18n.locale}`] ? scope.row[`region_${$i18n.locale}`] : scope.row.region}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('sustainable_buildings_structures')" align="center">
                        <template slot-scope="scope">
                            <div class="table-link-button c-green" style="padding:8px;">{{scope.row.count_sustainable | formatNumber }} {{$t('ta')}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('not_sustainable_buildings_structures')" align="center">
                        <template slot-scope="scope">
                            <div class="table-link-button c-yellow" style="padding:8px;"> {{scope.row.count_not_sustainable | formatNumber }} {{$t('ta')}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('not_sustainable_buildings4_structures')" align="center">
                        <template slot-scope="scope">
                            <div class="table-link-button c-red" style="padding:8px;"> {{scope.row.count_not_sustainable4 | formatNumber }} {{$t('ta')}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('total_#_buildings_structures')" align="center">
                        <template slot-scope="scope">
                            <div class="table-link-button " style="padding:8px;"> {{scope.row.count_building | formatNumber }} {{$t('ta')}}</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
    </template>

    <script>
    import UzMap from '@/components/UzMap.vue'
    export default {
        components: {
            UzMap,
        },
        data() {
            return {
                rendered: true,
                transport_facility_statistic: [],
                Org: {},
                CatList: [],
                organization: 1514,
                a: [],
                activeName: 1,
                delay: 10,
                options: {
                    useEasing: true,
                    useGrouping: true,
                    separator: ',',
                    decimal: '.',
                    prefix: '',
                    suffix: ''
                },
                mainFilter: '',
                region: "",
                categoriess: [],
                region: null,
                diagram: [],
                distric: "",
                disabled1: true,
                loading: false,
                statistica: {},
                RegionSeries: [{
                        name: this.$t('new_buildings'),
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    },
                    {
                        name: this.$t('not_sustainable_buildings'),
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    },
                    {
                        name: this.$t('invalid_buildings'),
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    },

                ],
                TransportSeries: [{
                        name: this.$t('valid_transport'),
                        data: []
                    },
                    {
                        name: this.$t('not_sustainable_transports'),
                        data: []
                    },
                    {
                        name: this.$t('invalid_transports'),
                        data: []
                    },

                ],
                count: {
                    users: 0,
                    all_buildings: 0,
                    new_buildings: 0,
                    existing_buildings: 0,
                    sustainable_buildings: 0,
                    not_sustainable_buildings: 0,
                    not_sustainable_buildings4: 0
                },
                filter: 'region',
                chartOptions1: {
                    series: [{
                    }],
                    chart: {
                        type: 'bar',
                        height: 700,
                        stacked: false,
                        toolbar: {
                            show: true,
                            offsetY: -20,
                            tools: {
                                download: false,
                                selection: false,
                                zoom: false,
                                zoomin: true,
                                zoomout: true,
                                pan: false,
                            },
                        },

                        zoom: {
                            enabled: true
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontSize: '12px',
                            colors: ["#304758"]
                        }
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            borderRadius: 10
                        },
                    },
                    colors: ['#50B498', '#FFF506', '#FF4E88'],

                    responsive: [{
                        breakpoint: 480,
                        options: {
                            legend: {
                                position: 'top',
                                offsetX: -20,
                                offsetY: 0
                            }
                        }
                    }],

                    xaxis: {
                        type: 'category',
                        categories: ["Андижон вилояти", "Бухоро вилояти", "Жиззах вилояти", "Қашқадарё вилояти", "Навоий вилояти", "Наманган вилояти", "Самарқанд вилояти", "Сурхондарё вилояти", "Сирдарё вилояти", "Тошкент шаҳри", "Тошкент вилояти", "Фарғона вилояти", "Хоразм вилояти", "Қорақалпоғистон Республикаси"],
                        tickPlacement: 'on',
                        labels: {
                            show: true,
                            rotate: -70,
                            rotateAlways: false,
                            hideOverlappingLabels: true,
                            showDuplicates: false,
                            trim: false,
                            minHeight: 120,
                            maxHeight: 300,
                            style: {
                                colors: [],
                                fontSize: '12px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                fontWeight: 400,
                                cssClass: 'apexcharts-xaxis-label',
                            },

                        },
                    },
                    legend: {
                        position: 'top',
                        offsetX: 0,
                        offsetY: 0,
                        itemMargin: {
                            horizontal: 5,
                            vertical: 25
                        },
                    },
                    fill: {
                        opacity: 1
                    }
                },
                chartOptions2: {
                    chart: {
                        type: 'bar',
                        height: 700,
                        stacked: true, 
                        stackType: '100%',
                        toolbar: {
                            show: true,
                            offsetY: -20,
                            tools: {
                                download: false,
                                selection: false,
                                zoom: false,
                                zoomin: true,
                                zoomout: true,
                                pan: false,
                            },
                        },

                        zoom: {
                            enabled: true
                        }
                    },
                    colors: ['#50B498', '#FFF506', '#FF4E88'],
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontSize: '10px',
                            colors: ["#304758"]
                        }
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            legend: {
                                position: 'top',

                                offsetY: 0
                            }
                        }
                    }],
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            borderRadius: 10
                        },
                    },
                    xaxis: {
                        type: 'category',
                        categories: ["Андижон вилояти", "Бухоро вилояти", "Жиззах вилояти", "Қашқадарё вилояти", "Навоий вилояти", "Наманган вилояти", "Самарқанд вилояти", "Сурхондарё вилояти", "Сирдарё вилояти", "Тошкент шаҳри", "Тошкент вилояти", "Фарғона вилояти", "Хоразм вилояти", "Қорақалпоғистон Республикаси"],
                        tickPlacement: 'on',
                        labels: {
                            show: true,
                            rotate: -70,
                            rotateAlways: false,
                            hideOverlappingLabels: true,
                            showDuplicates: false,
                            trim: false,
                            minHeight: 120,
                            maxHeight: 300,
                            style: {
                                colors: [],
                                fontSize: '12px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                fontWeight: 400,
                                cssClass: 'apexcharts-xaxis-label',
                            },
                        }

                    },
                    legend: {
                        position: 'top',
                        offsetX: 0,
                        offsetY: 0,
                        itemMargin: {
                            horizontal: 5,
                            vertical: 25
                        },
                    },
                    fill: {
                        opacity: 1
                    }

                },
                // series: [44, 55, 41, ],
                series: [],
                series2: [{
                    data: []
                }],
                chartOptions3: {
                    chart: {
                        type: 'donut',
                        height: 300

                    },
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontSize: '14px',
                            colors: ["#000"],
                            fontWeight: '400',
                        }
                    },
                    colors: ['#50B498', '#FFF506', '#FF4E88'],
                    labels: ['Сейсмик мустаҳкам бинолар', 'Инструментал техник текширув талаб этиладиган бинолар', "Сейсмик заиф бинолар"],
                    tooltip: {
                        enabled: true,
                        theme: 'dark',
                        fillSeriesColor: false,
                        style: {
                            fontSize: '12px',
                            color: '#000'
                        },
                    },
                    legend: {
                        position: 'bottom'
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                },
                chartOptionsTransport3: {
                    chart: {
                        type: 'pie',
                        height: 300

                    },
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontSize: '14px',
                            colors: ["#000"],
                            fontWeight: '400',
                        }
                    },
                    colors: ['#50B498', '#FFF506', '#FF4E88'],
                    labels: ['Сейсмик мустаҳкам транспорт иншоотлар', 'Инструментал техник текширув талаб этиладиган транспорт иншоотлар', "Сейсмик заиф транспорт иншоотлар"],
                    tooltip: {
                        enabled: true,
                        theme: 'dark',
                        fillSeriesColor: false,
                        style: {
                            fontSize: '12px',
                            color: '#000'
                        },
                    },
                    legend: {
                        position: 'bottom'
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                },

                chartOptions4: {
                    chart: {
                        type: 'bar',
                        height: 300,
                        toolbar: {
                            show: false,
                        }
                    },
                    plotOptions: {
                        bar: {
                            barHeight: '100%',
                            distributed: true,
                            horizontal: true,
                            borderRadius:16,
                            dataLabels: {
                                position: 'bottom'
                            },
                        }
                    },
                    colors: ['#50B498', '#F9E400', '#FF4E88'],
                    dataLabels: {
                        enabled: true,
                        textAnchor: 'start',
                        style: {
                            colors: ['#000'],
                            fontWeight: '400',
                            fontSize: '14px',

                        },
                        formatter: function (val, opt) {
                            return opt.w.globals.labels[opt.dataPointIndex] + ": " + val + "та"
                        },
                        offsetX: 0,
                    },
                    stroke: {
                        width: 1,
                        colors: ['#fff']
                    },
                    grid: {
                        yaxis: {
                            lines: {
                            show: false,
                            }
                        }
                    },
                    xaxis: {
                        categories: ['Сейсмик мустаҳкам бинолар', 'Инструментал техник текширув талаб этиладиган бинолар', "Сейсмик заиф бинолар"],
                    },
                    yaxis: {
                        labels: {
                            show: false
                        }
                    },
                    tooltip: {
                        theme: 'dark',
                        x: {
                            show: true
                        },
                        y: {
                            title: {
                                formatter: function (val) {
                                    return ''
                                }
                            }
                        }
                    }
                },
                chartOptionsTransport4: {
                    chart: {
                        type: 'bar',
                        height: 300,
                        toolbar: {
                            show: false,
                        }
                    },
                    plotOptions: {
                        bar: {
                            barHeight: '100%',
                            distributed: true,
                            horizontal: true,
                            borderRadius:16,
                            dataLabels: {
                                position: 'bottom'
                            },
                        }
                    },
                    colors: ['#50B498', '#FFF506', '#FF4E88'],
                    dataLabels: {
                        enabled: true,
                        textAnchor: 'start',
                        style: {
                            colors: ['#000'],
                            fontWeight: '400',
                            fontSize: '14px',

                        },
                        formatter: function (val, opt) {
                            return opt.w.globals.labels[opt.dataPointIndex] + ": " + val + "та"
                        },
                        offsetX: 0,
                    },
                    stroke: {
                        width: 1,
                        colors: ['#fff']
                    },
                    grid: {
                        yaxis: {
                            lines: {
                            show: false,
                            }
                        }
                    },
                    xaxis: {
                        categories: ['Сейсмик мустаҳкам транспорт иншоотлар', 'Инструментал техник текширув талаб этиладиган транспорт иншоотлар', "Сейсмик заиф транспорт иншоотлар"],
                    },
                    yaxis: {
                        labels: {
                            show: false
                        }
                    },
                    tooltip: {
                        theme: 'dark',
                        x: {
                            show: true
                        },
                        y: {
                            title: {
                                formatter: function (val) {
                                    return ''
                                }
                            }
                        }
                    }
                },
                MapData: [],
                MapDataTransport: [],
                StatisticRegion: [{}],
                region_statistic: 0,
                OrganizarionsList1: [],
                OrganizarionsList2: [],
                OrganizarionsList3: [],
                org_id1: '',
                org_id2: '',
                router_link: "&region=0",
                transportFacilities: {},
                statType:'COUNT'

            }

        },
        computed: {
            dictionary() {
                return this.$store.state.dictionary;
            },
            transport_facility_condition() {
                return this.mainFilter == 'transport_facility'
            },
            regions() {
                return this.$store.state.dictionary.region;
            },
            // role() {
            //     let a = "";
            //     if ("role" in this.$store.state.me) {
            //         a = this.$store.state.me.role.name;
            //     }
            //     return a;
            // },
            role() {
                return this.$store.state.role;
            },
            me() {
                return this.$store.state.me;
            },
        },
        watch: {
            filter(val) {
                if (val == 'region') {
                    this.GetStatistic('region')
                    this.GetStatisticTop()
                } else {
                    this.GetStatistic('buildinggroup')
                    this.GetStatisticTop()
                }
            },
            activeName(val) {
                if (val == 1 || val == 2) {
                    this.GetStatistic('region')
                    this.GetStatisticTop()
                }
                if (val == 3) {
                    this.OrgGet(1514)
                }
                if (val == 4) {
                    if (this.me.role.name == 'org_admin' || this.me.role.name == 'ministry_admin') {
                        this.org_id2 = this.me.organization.id
                    } else {
                        this.GetRegionStatistic(this.org_id2, this.region_statistic)
                    }
                }
                if (val == 5) {
                    this._GetTransportFacilities()
                }
                this.$router.push(`/?page=${val}`)
            },
            categories(val) {
                this.chartOptions.xaxis.categories = this.categories
            },
            region(val) {
                if (val != null) {
                    this.GetStatistic('district')
                    this.GetStatisticTop()
                } else {
                    this.GetStatistic('region')
                    this.GetStatisticTop()
                }

            },
            organization(val) {
                this.OrgGet(val)
            },
            region_statistic(val) {
                this.GetRegionStatistic(this.org_id2, val)
                this.$router.push(`/?page=${this.activeName}&org_id1=${this.org_id1}&org_id2=${this.org_id2}&region=${val}`)
                this.router_link = `org_id1=${this.org_id1}&org_id2=${this.org_id2}&region=${this.region_statistic}`
            },
            role() {
                this.refresh()
            },
            org_id2(val) {
                this.$router.push(`/?page=${this.activeName}&org_id1=${this.org_id1}&org_id2=${this.org_id2}&region=${this.region_statistic}`)
                this.router_link = `org_id1=${this.org_id1}&org_id2=${this.org_id2}&region=${this.region_statistic}`
                this.GetRegionStatistic(val, this.region_statistic)
            },
            me() {
                if (this.me.role.name == 'org_admin' || this.me.role.name == 'ministry_admin') {
                    this.org_id2 = this.me.organization.id
                }
            },
            '$i18n.locale'() {
                if (this.activeName == 1) {
                    if (this.filter == 'region') {
                        if (this.region != null) {
                            this.GetStatistic('district')
                            this.GetStatisticTop()
                        } else {
                            this.GetStatistic('region')
                            this.GetStatisticTop()
                        }

                    } else {
                        this.GetStatistic('buildinggroup')
                       this.GetStatisticTop()

                    }
                }

            }

        },

        methods: {
            forceRender () {
                setTimeout(() => {
                    this.rendered = false;
                    setTimeout(() => {
                        this.rendered = true
                    }, 50)
                })
            },
            OrgIdchange() {
                this.$router.push(`/?page=${this.activeName}&org_id1=${this.org_id1}&region=${this.region_statistic}`)
                this.router_link = `org_id1=${this.org_id1}&region=${this.region_statistic}`
                this.OrganizarionsList2 = [{}]
                this.loading = true;
                if (this.org_id1 != '') {
                    axios.get(`/organization/${this.org_id1}/`)
                        .then((response) => {
                            this.OrganizarionsList2 = response.data.children;
                            this.org_id2 = ''
                            this.region_statistic = 0
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.region_statistic = 0
                    this.org_id2 = ''
                    this.GetRegionStatistic('', 0)

                }

            },
            buildingTypeFilter() {
                if (this.mainFilter === 'transport_facility') {
                    axios.get(`transport_facility/get_active`).then((res) => {
                        this.transport_facility_statistic = res.data;
                        this.$router.push({ path: this.$route.path, query: { ...this.$route.query, buildingType: this.mainFilter } });
                        this.org_id1 = '',
                        this.region_statistic = 0,
                        this.org_id2 = ''
                    });
                } else {
                    const params = {};
                    if (this.mainFilter.trim() !== '') {
                        params.buildingtype = this.mainFilter;
                    }
                    axios.get(`/building/organization/statistic/`, { params })
                        .then((res) => {
                            this.StatisticRegion = res.data;
                            this.$router.push({ path: this.$route.path, query: { ...this.$route.query, buildingType: this.mainFilter } });
                            this.org_id1 = '',
                            this.region_statistic = 0,
                            this.org_id2 = ''
                        });
                }
            },
            GetAllCount(data) {
                var count_building = 0
                var count_sustainable = 0
                var count_not_sustainable = 0
                var count_not_sustainable4 = 0
                for (let i = 0; i < data.length; i++) {
                    count_building = count_building + data[i].count_building
                    count_sustainable = count_sustainable + data[i].count_sustainable
                    count_not_sustainable = count_not_sustainable + data[i].count_not_sustainable
                    count_not_sustainable4 = count_not_sustainable4 + data[i].count_not_sustainable4
                }
                if (this.regional_statistics) {
                    this.StatisticRegion.push({
                        "id": 0,
                        "region": this.$t('all_count'),
                        "count_building": count_building,
                        "count_sustainable": count_sustainable,
                        "count_not_sustainable": count_not_sustainable,
                        "count_not_sustainable4": count_not_sustainable4
                    })
                } else {
                    this.StatisticRegion.push({
                        "id": 0,
                        "region": this.$t('all_count'),
                        "count_building": count_building,
                        "count_sustainable": count_sustainable,
                        "count_not_sustainable": count_not_sustainable,
                        "count_not_sustainable4": count_not_sustainable4
                    })
                }

            },
            GetStatisticTop() {
                this.loading = true
                if (this.activeName == 1 && this.filter == 'region' && this.region != null) {
                    axios.get(`/building/statistic/${this.region}`)
                        .then(res => {
                            this.statistica = res.data
                            this.filterStatistic(res.data)
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    axios.get('/building/statistic/')
                        .then(res => {
                            this.statistica = res.data
                            if (this.activeName == 1) {
                                this.filterStatistic(res.data)
                            }

                        })
                        .finally(() => {
                            this.loading = false
                        })
                }
            },
            GetStatistic(a) {
                if (this.role === 'admin' || this.role === 'org_admin' || this.role == 'ministry_admin' || this.role == 'ministry') {
                    if (a == 'district' && this.region != null) {
                        axios.get(`/building/districtstatistic/${this.region}/`)
                            .then(res => {
                                this.diagram = res.data
                                this.filterDiagramRegional(res.data, a)
                            })
                            .finally(() => {
                                this.loading = false
                            })
                    }
                    if (a == 'region') {
                        axios.get('/building/statisticdiagram/')
                            .then(res => {
                                this.diagram = res.data

                                this.filterDiagramRegional(res.data.regional_statistics, a)
                            })
                            .finally(() => {
                                this.loading = false
                            })
                    }
                    if (a == 'buildinggroup') {
                        axios.get('/building/statisticdiagram/')
                            .then(res => {
                                this.diagram = res.data
                                this.filterDiagramRegional(res.data.building_group_statistic, a)
                            })
                            .finally(() => {
                                this.loading = false
                            })
                    }
                }

            },
            GetRegionStatistic(org_id, region_id) {
              console.log(region_id, org_id)
                this.loading = true;
                let link = '';
                let dataVariable = '';

                if (!this.transport_facility_condition) {
                    link = region_id ? `/building/organization/statistic/${org_id}/${region_id}/` : org_id ? `/building/organization/statistic/${org_id}/` : '/building/organization/statistic/';
                    dataVariable = 'StatisticRegion';
                } else {
                    link = region_id ? `/transport_facility/get_active?main_org=${org_id}&region=${region_id}` : org_id ? `/transport_facility/get_active?main_org=${org_id}` : '/transport_facility/get_active/';
                    dataVariable = 'transport_facility_statistic';
                }

                axios.get(link)
                    .then(res => {
                        this[dataVariable] = res.data;
                        this.GetAllCount(res.data);
                        console.log(this.dataVariable);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
},

            filterDiagramRegional(a, b) {
                var safe = []
                var unsafe = []
                var unsafe4 = []
                var count = []
                var category = []
                var points = []
                let i = 0;
                for (i = 0; i < a.length; i++) {
                    if (a[i].count_building > 0) {
                        if (a[i].count_building >= 1000000000) {
                            a[i].count_building= (a[i].count_building / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
                        }
                        if (a[i].count_building >= 1000000) {
                            a[i].count_building= (a[i].count_building / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
                        }
                        if (a[i].count_building >= 1000) {
                            a[i].count_building=(a[i].count_building / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
                        }
                        safe.push(a[i].count_sustainable)
                        unsafe.push(a[i].count_not_sustainable)
                        unsafe4.push(a[i].count_not_sustainable4)
                        count.push(a[i].count_building)
                        points.push({
                            x: this.$i18n.locale=='cyr' ? a[i][b]: a[i][`${b}_${this.$i18n.locale}`] ? a[i][`${b}_${this.$i18n.locale}`]:a[i][b],
                            y: this.$i18n.locale=='cyr' ? a[i][b]: a[i][`${b}_${this.$i18n.locale}`] ? a[i][`${b}_${this.$i18n.locale}`]:a[i][b],
                            seriesIndex: 0,
                            label: {
                                borderColor: 'rgb(226, 243, 224)',
                                text: `${a[i].count_building}`,
                                textAnchor: 'middle',
                                offsetX: 0,
                                offsetY: -15,
                                style: {
                                    color: '#000',
                                    background: 'rgb(226, 243, 224)',
                                },

                            }
                        })
                    } else {
                        safe.push(0)
                        unsafe.push(0)
                        unsafe4.push(0)
                        count.push(0)
                        points.push({
                            x: this.$i18n.locale=='cyr' ? a[i][b]: a[i][`${b}_${this.$i18n.locale}`] ? a[i][`${b}_${this.$i18n.locale}`]:a[i][b],
                            y: this.$i18n.locale=='cyr' ? a[i][b]: a[i][`${b}_${this.$i18n.locale}`] ? a[i][`${b}_${this.$i18n.locale}`]:a[i][b],
                            seriesIndex: 0,

                            label: {
                                borderColor: 'rgb(226, 243, 224)',
                                textAnchor: 'middle',
                                offsetX: 0,
                                offsetY: -15,
                                style: {
                                    color: '#000',
                                    background: 'rgb(226, 243, 224)',

                                },
                                text: '0',
                            }
                        })

                    }
                    if (this.$i18n.locale != 'cyr') {
                        category.push(a[i][`${b}_${this.$i18n.locale}`] ? a[i][`${b}_${this.$i18n.locale}`]:a[i][b])
                        this.a = points
                    } else {
                        category.push(a[i][b])
                    }

                }
                var RegionSeries = [{
                        name: this.$t('valid_buildings'),
                        data: safe
                    },
                    {
                        name: this.$t('not_sustainable_buildings'),
                        data: unsafe
                    },
                    {
                        name: this.$t('invalid_buildings'),
                        data: unsafe4
                    }

                ]
                var TransportSeries = [{
                        name: this.$t('valid_transport'),
                        data: safe
                    },
                    {
                        name: this.$t('not_sustainable_buildings'),
                        data: unsafe
                    },
                    {
                        name: this.$t('invalid_buildings'),
                        data: unsafe4
                    }

                ]

                this.MapData = [{
                        name: this.$t('valid_buildings'),
                        color: '#50B498',
                        data: safe
                    },
                    {
                        name: this.$t('not_sustainable_buildings'),
                        color: 'rgb(243, 137, 66)',
                        data: unsafe
                    },
                    {
                        name: this.$t('invalid_buildings'),
                        color: 'red',
                        data: unsafe4
                    }

                ]

                var chartOptions1 = {

                    ...this.chartOptions1,
                    ...{
                        series: this.activeName === 1 ? RegionSeries : TransportSeries,
                        annotations: {
                            position: 'front',
                            points: points,

                        },
                        xaxis: {

                            type: 'category',
                            categories: category,
                            tickPlacement: 'on',

                        },
                    }
                };
                var chartOptions2 = {
                    series: this.activeName === 1 ? RegionSeries : TransportSeries,
                    ...this.chartOptions2,
                    ...{
                        xaxis: {
                            categories: category
                        }
                    }
                };
                this.$refs.chart1.updateOptions(chartOptions1, true, true, true);
                this.$refs.chart2.updateOptions(chartOptions2, false, true, true);
                // this.$refs.chart1.appendData(RegionSeries)

            },
            filterStatistic(data) {
                var donut = [data.sustainable_buildings, data.not_sustainable_buildings, data.not_sustainable_buildings4]
                var bar = [{
                    data: donut
                }]
                var categories=[this.$t('sustainable_buildings'), this.$t('not_sustainable_buildings'), this.$t('not_sustainable_buildings4')]
                var chartOptionsDonut = {

                    ...this.chartOptions3,

                    ...{
                        series: donut,
                        labels: categories,

                    }
                };
                var chartOptionsBar = {

                    ...this.chartOptions4,
                    ...{
                        series: bar,
                        xaxis: {
                        categories: categories,
                    },
                    }
                };
                this.$refs.chart3.updateOptions(chartOptionsDonut, true, true, true);
                this.$refs.chart4.updateOptions(chartOptionsBar, false, true, true);

            },
            OrgGet(id) {
                this.loading = true
                axios.get(`/building/statisticorganization/${id}/`)
                    .then((response) => {
                        this.Org = response.data;
                        this.filterDiagramOrg(response.data)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            filterDiagramOrg(a) {
                var safe = []
                var unsafe = []
                var unsafe4 = []
                var category = []
                var points = []
                var count = []
                let i = 0;
                this.count.users = 0;
                this.count.all_buildings = 0;
                this.count.new_buildings = 0;
                this.count.existing_buildings = 0;
                this.count.sustainable_buildings = 0;
                this.count.not_sustainable_buildings = 0;
                this.count.not_sustainable_buildings4 = 0;
                var organization = ''
                for (i = 0; i < a.length; i++) {
                    if (a[i].all_buildings > 0) {
                        safe.push(a[i].sustainable_buildings)
                        unsafe.push(a[i].not_sustainable_buildings)
                        unsafe4.push(a[i].not_sustainable_buildings4)
                        count.push(a[i].all_buildings)
                        if (this.$i18n.locale != 'cyr') {
                            organization = a[i][`organization_${this.$i18n.locale}`] ? a[i][`organization_${this.$i18n.locale}`] : a[i].organization
                        } else {
                            organization = a[i].organization
                        }
                        points.push({
                            x: organization,
                            y: organization,
                            seriesIndex: 0,

                            label: {
                                borderColor: 'rgb(226, 243, 224)',
                                text: `${a[i].all_buildings}`,
                                textAnchor: 'middle',
                                offsetX: 0,
                                offsetY: -15,
                                style: {
                                    color: '#000',
                                    background: 'rgb(226, 243, 224)',
                                },

                            }
                        })
                    } else {
                        safe.push(0)
                        unsafe.push(0)
                        unsafe4.push(0)
                        count.push(0)
                        points.push({
                            x: organization,
                            y: organization,
                            seriesIndex: 0,

                            label: {
                                borderColor: 'rgb(226, 243, 224)',
                                textAnchor: 'middle',
                                offsetX: 0,
                                offsetY: -15,
                                style: {
                                    color: '#000',
                                    background: 'rgb(226, 243, 224)',

                                },
                                text: '0',
                            }
                        })

                    }
                    this.count.users = this.count.users + a[i].users
                    this.count.all_buildings = this.count.all_buildings + a[i].all_buildings
                    this.count.new_buildings = this.count.new_buildings + a[i].new_buildings
                    this.count.existing_buildings = this.count.existing_buildings + a[i].existing_buildings
                    this.count.sustainable_buildings = this.count.sustainable_buildings + a[i].sustainable_buildings
                    this.count.not_sustainable_buildings = this.count.not_sustainable_buildings + a[i].not_sustainable_buildings
                    this.count.not_sustainable_buildings4 = this.count.not_sustainable_buildings4 + a[i].not_sustainable_buildings4
                    category.push(a[i].organization)
                    this.a = points

                }

            },
            refresh() {
                if (this.role == 'reservoir') {
                    this.activeName = 2
                }
                if (this.role == 'ministry') {
                    if (this.activeName == 1) {
                        this.GetStatistic('buildinggroup')
                        this.GetStatisticTop()
                    }

                } else if (this.role) {
                    if (this.activeName == 1) {
                        this.GetStatisticTop()
                        this.GetStatistic('region')
                    }

                }
                if (this.role == 'admin') {
                    this.OrgGet(1514)
                    axios.get('/organization/?limit=15&level=1')
                    .then((response) => {
                        this.CatList = response.data.results;
                    })
                    axios.get('/organization/?limit=15&level=1')
                    .then((response) => {
                        this.OrganizarionsList1 = response.data.results;

                    })
                    this.loading = true
                    if (this.org_id1 != '') {
                        axios.get(`/organization/${this.org_id1}/`)
                        .then((response) => {
                            this.OrganizarionsList2 = response.data.children;
                            this.org_id2 = response.data.children[0].id
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                    } else {
                        this.GetRegionStatistic(this.org_id2, this.region_statistic)
                    }
                }
            },
            DownloadExel(url, name) {
                this.loading = true
                axios({
                        url: url,
                        method: 'GET',
                        responseType: 'blob',
                    }).then((response) => {
                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');

                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', `${name}` + ".xlsx");
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            chartOptionTaransport(regions){
                let chartOptions1 = {

                    ...this.chartOptions1,
                    ...{
                        series: TransportSeries,
                        annotations: {
                            position: 'front',
                            points: points,

                        },
                        xaxis: {
                            type: 'category',
                            categories: regions,
                            tickPlacement: 'on',

                        },
                    }
                };

                this.$refs.chart1.updateOptions(chartOptions1, true, true, true);
            },
            _GetTransportFacilities(){
                this.loading = true
                axios.get(`/transport_facility_statistic/`)
                .then((res) => {
                    this.transportFacilities = res.data
                    let dataInfo  = {
                        info: res.data.chart,
                        active: this.activeName
                    }

                    let first_seismic = []
                    let second_seismic = []
                    let third_seismic = []
                    let regions = []

                    this.TransportSeries = [
                        {
                            name: this.$t('valid_transport'),
                            data: []
                        },
                        {
                            name: this.$t('not_sustainable_transports'),
                            data: []
                        },
                        {
                            name: this.$t('invalid_transports'),
                            data: []
                        },

                    ],

                    res.data.chart.forEach((elem) => {
                        first_seismic.push(elem.first_seismic)
                        second_seismic.push(elem.second_seismic)
                        third_seismic.push(elem.third_seismic)

                        this.TransportSeries[0].data.push(elem.first_seismic)
                        this.TransportSeries[1].data.push(elem.second_seismic)
                        this.TransportSeries[2].data.push(elem.third_seismic)

                        regions.push(elem.region.name_cyr)
                    })

                    this.MapDataTransport = [
                        {
                            name: this.$t('valid_transport'),
                            color: '#50B498',
                            data: first_seismic
                        },
                        {
                            name: this.$t('not_sustainable_transports'),
                            color: 'rgb(243, 137, 66)',
                            data: second_seismic
                        },
                        {
                            name: this.$t('invalid_transports'),
                            color: 'red',
                            data: third_seismic
                        }

                    ]




                    this.series = []
                    this.series.push(this.transportFacilities.seismic.first_seismic)
                    this.series.push(this.transportFacilities.seismic.second_seismic)
                    this.series.push(this.transportFacilities.seismic.third_seismic)

                        this.series2[0].data = JSON.parse(JSON.stringify(this.series))

                    this.$root.$emit('uzmap_info', dataInfo)

                    this.chartOptionTaransport(regions)
                })
                .finally(() => {
                    this.loading = false;
                    this.forceRender();
                })
            }

        },
        mounted() {
            this.activeName = this.$route.query.page && this.$route.query.page != undefined ? this.$route.query.page : 1
            this.region_statistic = this.$route.query.region && this.$route.query.region != undefined ? parseInt(this.$route.query.region) : 0
            this.org_id1 = this.$route.query.org_id1 && this.$route.query.org_id1 != undefined ? parseInt(this.$route.query.org_id1) : ''
            this.org_id2 = this.$route.query.org_id2 && this.$route.query.org_id2 != undefined ? parseInt(this.$route.query.org_id2) : ''
            this.refresh()

        },

    };
    </script>

    <style lang="scss">

    .header-items {
        .header-item {
            @media screen and (max-width:1600px) {
                padding: 15px 5px;
                font-size: 13px;
            }
        }
    }
    .org-building-statistics{
        border-collapse: collapse;
        width: 100%;
        td,tr{
            border-collapse: collapse;
        }
        td{
            padding:5px;
        }
        .org-building-statistics-text{
            color: #00363E;
            font-size: 12px;
            text-align: right;
            font-weight: 600;
        }
        .org-building-statistics-number{
            font-weight: 600;
            font-size: 12px;
            padding-left: 0px !important;
            width: 100px;
        }
    }
    .building-progress-bar{
            width: 200px;
            height: 15px;
            display: flex;
            align-items: center;
            .building-progress-bar-bar{
                height: 100%;
                min-width: 2px;
                max-width: calc(100% - 10px);
                border-radius: 15px;
            }
            hr{
                flex: 1;
                height: 1px;
                border-bottom: 1px solid rgba(25, 152, 170, 0.4);;
                margin-left: 10px;
                min-width: 5px;
            }
        }


    .total-count {
        padding: 20px 0px 10px 0px;
        font-weight: 800 !important;
        text-align: center;
    }

    .top-header {
        margin-bottom: 20px;
    }

    .h-full {
        height: 100%;
    }

    .df {
        display: flex;
    }

    .jcsb {
        justify-content: space-between;
    }

    .jcc {
        justify-content: center;
    }

    .aic {
        align-items: center;
    }

    .ctatic-header {
        padding-bottom: 30px;
        padding-top: 20px;
    }

   



    .building-card {
        padding: 20px;
        width: 50%;
        border-radius: 10px;

    }

    .obj-create { 
        padding: 20px;
        border-radius: 16px;   
        box-shadow: 0 4px 10px 4px rgba(0,0,0,0.2);
    }

    .cards {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, minmax(300px, 1fr));
        gap: 1rem;
    }

    .card1 {  
        flex: 1;
        cursor: pointer;
        &.mw-25{
            min-width: 26%;
        }
        &.mw-200px{
            min-width: 200px;
            max-width: 200px;
            width: 200px;
        }
    }
    .reservoir-statistic-info{ 
        padding: 10px 15px;
        text-align: center;
        background: #00DA57;
        border-radius: 10px;
        font-weight: 600;
        color: #fff;
        &.unsafe{
            background: #DA0000;
        }
        &.checking{
            color: #004787; 
            box-shadow: 0 4px 10px 4px rgba(0,0,0,0.2);
            background: linear-gradient(135deg, #F2F2F2 0%, #FFFFFF 100%);
        }
    }

    .card1:hover {
        transform: scale(1.01)
    }

    .safe .card {
        background: #50B498;
    }

    .unsafe .card {
        background: #FF4E88;
    }

    .unsafe4 .card {
        background: #FFF506;
    }

    .card {
        width: 100%;
        height: 170px;
        border-radius: 16px;
        box-shadow: 0 4px 10px 4px rgba(0,0,0,0.2);
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        &.only-icon{
            justify-content: center;
            align-items: center;
        }
        &.total-reservoir{
            background: #004787;
        }
        &.primary{
            background: #004787;
        }
    }

    .card:hover {
        box-shadow: 0 4px 10px 4px rgba(0,0,0,0.2);
    }
    .card.invest_card{
      box-shadow: unset;
    }
    .card {
      .invest_card_left {
        width: 60%;
        display: flex;
        font-size: 14px;
        border-radius: 10px 0px 0px 10px;
        &:hover{
            box-shadow: 0 4px 10px 4px rgba(0,0,0,0.2);
        }
        .card-text{
          padding-left: 0;
          p{
            font-size: 12px;
            background: #000 !important;
          }
          h1{
            font-size: 20px;
          }
        }
      }
      span{
        border: 1px solid rgba(25, 152, 170, 0.2);
        //height: 140px;
        //margin: auto;
      }
      .invest_card_right {
        width: 40%;
        display: flex;
        font-size: 14px;
        border-radius: 0px 10px 10px 0px;
        &:hover{
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
        }
        .card-text{
          .invest_icon_text{
            display: flex;
            img{
              margin-right: 17px;
            }
          }
          p{
            font-size: 12px;
            justify-content: left;
          }
          h1{
            font-size: 20px;
          }
        }
      }
    }

    .card-icon {
        width: 100%; 
        padding: 20px;
        display: flex;
        justify-content: space-between;
        gap: 10px;
    }

    .card-text {  
        text-align: right;
        width: 100%;
        margin-top: 16px;
    }

    .card-text p {
        font-size: 14px;
        font-weight: 600;
        color: #004787;
        margin: 0;  
    }

    .card-text h1 {
        font-size: 24px;
        font-weight: 800 !important; 
        color: #004787;
    }
    .total-reservoir{
        .card-text p {
            color: #fff;
            font-size: 16px;
        }
        .card-text h1 {
            color: #fff;
            font-size: 20px;
        }
    }
    .w-50 {
        width: 50%;
    } 
    .diagram-item {
        width: 49.5%;
        min-width: 600px;
        height: inherit;
        margin-top: 20px;
        padding: 10px;

        @media screen and (max-width:1500px) {
            width: 100%;
        }

    }

    .filterStatistic {
        display: flex;
        background: #fff;
        border-radius: 10px;
        margin-bottom: 20px;
        flex-wrap: wrap;
        gap:10px;

    }

    .pb-0 {
        padding-bottom: 0 !important;
    }

    .filterStatistic .el-input__inner { 
        width: 250px;
    }

    .filterStatistic .el-input__icon {
        color: rgb(100, 100, 100) !important;
    }

    .diagram-div {
        width: 100%;
        padding: 0px 1px;
    }

    .diagram-div-item {
        width: 100%;
        min-height: 128px;
        border-bottom: 1px solid rgb(221, 221, 221);
        position: relative;
        padding-top: 5px;
        padding-left: 10px;
    }

    .diagram-stroke {}

    .diagram-stroke  {
        .diagram-stroke-content{
            overflow: hidden;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 500;
            margin-top: 1px;
        }

    }

    .diagram-stroke div:hover {
        opacity: 0.5;
    }

    .diagram-div-item:hover {
        opacity: 0.9;
    }

    .diagram-div-item .title {
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }

    .diagram-div-text {
        font-size: 12px;
    }
    .diagram-div-link{
        font-size: 14px;
        margin-bottom: 5px;
        text-decoration: underline;
        cursor: pointer;
        color: #004787;
    }

    .min-w-30 {
        min-width: 30px !important;
    }

    .w-40 {
        width: 40%;
    }

    .building-text {
        font-size: 13px;
        padding-right: 20px;
    }

    .owf-h {
        overflow: hidden !important;
    }

    .cut-text {
        text-overflow: ellipsis !important; 
        max-width: 700px !important;
        white-space: nowrap !important;
    }
    </style>
